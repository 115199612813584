@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
  margin: 0;
  /* font-family: 'Playfair Display', serif; */
  /* font-family: 'Rubik', sans-serif; */
font-family: 'Lato',
sans-serif;
letter-spacing:0.6px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #111111;
  color: white;

  scroll-behavior: smooth;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.rounded-image{
  border-radius: 40px 0 40px  0
}




/* Define variables */
:root {
  --body-bg: #10171E;
  --item-bg: #15202B;
  --item-border-color: #38444d;

  --skeleton-color: #1A2A35;
  --skeleton-border-radius: 3px;
  --skeleton-img-border-radius: 0.75rem;
  --skeleton-animation-speed: 1s;
}

/* Define reusable mixins and keyframes */
@keyframes skeleton {
  0% {
    opacity: 0.2;
    transform: translateY(6px) scale(0.98);
  }

  85%,
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

/* Global styles */
/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */

/* body {
  background-color: var(--body-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  min-height: 100vh;
  padding: 2rem 1rem;
} */

.tweet {
  background-color: var(--item-bg);
  padding: 0.8rem 0.75rem;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  border: 1px solid var(--item-border-color);
  border-radius: 0.5rem;
  overflow: hidden;
}

.tweet-content {
  margin-left: calc(48px + 10px);
}

.tweet-header {
  animation: skeleton var(--skeleton-animation-speed) ease-in-out forwards infinite;
  transform-origin: bottom;
}
.tweet-text {
  animation: skeleton var(--skeleton-animation-speed) ease-in-out forwards infinite;
  animation-delay: 200ms;
}

.tweet-footer {
  display: flex;
  margin-top: 1rem;
  animation: skeleton var(--skeleton-animation-speed) ease-in-out forwards infinite;
  transform-origin: bottom;
  animation-delay: 400ms;
}

.skeleton {
  background-color: var(--item-bg);
}

.skeleton-avatar {
  width: 48px;
  height: 48px;
  background-color: var(--skeleton-color);
  border-radius: 100%;
  float: left;
}

.skeleton-line {
  height: 0.7rem;
  background-color: var(--skeleton-color);
  border-radius: var(--skeleton-border-radius);
  margin-bottom: 0.3rem;
}

.skeleton-line.heading {
  height: 1rem;
  margin-bottom: 0.5rem;
}

.skeleton-img {
  height: 500px;
  background-color: var(--skeleton-color);
  border-radius: var(--skeleton-img-border-radius);
  margin-top: 1.5rem;
  animation: skeleton var(--skeleton-animation-speed) ease-in-out forwards infinite;
  animation-delay: 300ms;
}
.skeleton-img-slider {
  height: 1000px;
  background-color: var(--skeleton-color);
  border-radius: var(--skeleton-img-border-radius);
  margin-top: 1.5rem;
  animation: skeleton var(--skeleton-animation-speed) ease-in-out forwards infinite;
  animation-delay: 300ms;
}
.skeleton-img1 {
  height: 1000px;
  background-color: var(--skeleton-color);
  border-radius: var(--skeleton-img-border-radius);
  margin-top: 1.5rem;
  animation: skeleton var(--skeleton-animation-speed) ease-in-out forwards infinite;
  animation-delay: 300ms;
}

.skeleton-button {
  display: block;
  width: 26px;
  height: 26px;
  background-color: var(--skeleton-color);
  flex-shrink: 0;
}


.skeleton-button.rounded {
  border-radius: 100%;
}

.loader{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loaderSpin {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loaderSpin::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}




.noData{
width: 100vw;
height: calc(100vh - 400px);
display: flex;
justify-content: center;
align-items: center;
}

.noData p{
  font-size: 25px;
}


.shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
  }
  .img-circle {
    border-radius: 50%;
    vertical-align: middle;
    max-width: 90px;
    min-height: 90px;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
  }
  #your-space-slider {
    margin-top: 6%;
  }
  #your-space-slider .item {
    width: 390px;
    height: 390px;
  }
  #your-space-slider .item .HomeSlider{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  #your-space-slider .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  #your-space-slider .owl-item{
    display: flex;
    justify-content: center;
  }
  /* #your-space-slider .owl-stage-outer{
    padding:10px 10px;
  } */
  #your-space-slider.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  #your-space-slider .owl-nav .owl-prev:hover{
    transform: translate3d(-10px, 0, -10px) scale(0.8);
    background: none;
  }
  #your-space-slider .owl-nav .owl-next:hover{
    transform: translate3d(10px, 0, 10px) scale(0.8);
    background: none;
  }
  #your-space-slider.owl-carousel .owl-dots {
    display: inline-block;
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  #your-space-slider.owl-carousel .owl-nav {
      position: absolute;
      margin: 0px;
      top: 37%;
      justify-content: space-between;
      display: flex;
  }
  @media screen and (min-width: 1535px) and (max-width: 3500px) {
    #your-space-slider.owl-carousel .owl-nav {
      width: 105%;
      left: -40px;
    }
  }
  @media screen and (min-width: 1420px) and (max-width: 1535px) {
    #your-space-slider.owl-carousel .owl-nav {
      width: 110%;
      left: -59px;
    }
  }
  @media screen and (min-width: 1274px) and (max-width: 1420px) {
    #your-space-slider.owl-carousel .owl-nav {
      width: 108%;
      left: -50px;
    }
  }
    @media screen and (min-width: 1100px) and (max-width: 1274px) {
      #your-space-slider.owl-carousel .owl-nav {
        width: 110%;
        left: -50px;
      }
    }
    @media screen and (min-width: 1023px) and (max-width: 1100px) {
      #your-space-slider.owl-carousel .owl-nav {
        width: 106%;
        left: -30px;
      }
    }
    @media screen and (min-width: 870px) and (max-width: 1023px) {
      #your-space-slider.owl-carousel .owl-nav {
        width: 119%;
        left: -71px;
      }
    }
    @media screen and (min-width: 763px) and (max-width: 870px) {
      #your-space-slider.owl-carousel .owl-nav {
        width: 113%;
        left: -40px;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 763px) {
      #your-space-slider.owl-carousel .owl-nav {
        width: 117%;
        left: -40px;
      }
    }
    @media screen and (min-width: 529px) and (max-width: 750px) {
      #your-space-slider.owl-carousel .owl-nav {
        width: 100%;
        left: 0px;
      }
    }
    @media screen and (min-width: 0px) and (max-width: 529px) {
      .owl-nav{
        display: none !important;
      }
    }
  #your-space-slider.owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }

  #your-space-slider.owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #d2ab66 !important;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  } 

  #your-space-slider.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }


  #customer-testimonoals {
    margin-top: 6%;
  }
  .testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    background: linear-gradient(100deg, #FF8066, #FF918D);
    /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0,0,0,0.12), 0px 5px 7px rgba(0,0,0,0.5);
  }
  #customer-testimonoals .item {
    text-align: center;
    /* padding: 50px; */
    margin-bottom: 0px;
    opacity: 0.2;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
  #customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  #customer-testimonoals.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  #customer-testimonoals.owl-carousel .owl-dots {
    display: inline-block;
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  #customer-testimonoals.owl-carousel .owl-nav {
      position: absolute;
      margin: 0px;
      /* margin-bottom: -30px; */
      right: 24%;
      bottom: 0;
      display: flex;
      }
      #customer-testimonoals.owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }

  #customer-testimonoals.owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #d2ab66 !important;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  } 

  #customer-testimonoals.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .contactAddress{
    display: flex;
    flex-direction: column;
  }